import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, Card, CardHeader, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Table, Tabs, TextField, Typography } from "@mui/material";
import { TableContainer, TableBody, TableCell, TableHead, TableRow, Chip, CardContent } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE, DocumentType, FileCategory } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_BG, THEME_COLOR, THEME_HIGHLIGHT } from "../../constants/style";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import TabPanel from "../../components/shared-tab-panel";
import { constructImgUrl } from "../../constants/settings";
import { toJS } from "mobx";
import CollapsedSection from "../../components/shared-collapsed-section";
import Heading from "../../components/shared-heading";
import SlideDown from "react-slidedown";

// slide icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import VmVisitSlideDownCard from "../../components/shared-VisitSlideDownCard";
import TraineeshipCard from "../../components/shared-traineeship-card";

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StudentProfilePage = observer(({ location }) => {
  const { rootStore, groupStore, studentStore, userStore, fileStore } = useStores();
  const [studentId, setStudentId] = useState<string>(location.state && location.state.studentId ? location.state.studentId : "0");
  const [currentStudent, setCurrentStudent] = useState<any>();
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tabValue, setTabValue] = useState<number>(1);
  const [modalTabValue, setModalTabValue] = useState<number>(0);
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [traineeshipId, setTraineeshipId] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>("");

  const [fold, setFold] = useState<number>(0);
  const [traineeshipFold, setTraineeshipFold] = useState<number>(0);
  const [currentTraineeship, setCurrentTraineeship] = useState<any>()

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) == 0 ||
      studentStore.studentProfileList.data.length != ApiDataResponseListLengthCheck(ApiDataListType.STUDENT)) {
      studentStore.getStudentProfileList(0, 0, {})
        .then(() => {
          if (location.state && location.state.studentId)
            onSelectStudent(location.state.studentId);
        });
    }
    if (location.state && location.state.studentId)
      onSelectStudent(location.state.studentId);
  }, []);

  const onChangeStudent = (event: SelectChangeEvent) => {
    let id = event.target.value;
    onSelectStudent(id);
  };

  const onSelectStudent = (id: string) => {
    if (id === "0") setCurrentStudent(null);
    else {
      studentStore.getStudentProfileById(+id)
        .then((res) => setCurrentStudent(res));
      studentStore.getVisitListByStudentId(+id, false, PAGE_SIZE, 0);
    }
    setTraineeshipId(0);
    setStudentId(id);
    setTabValue(1);
    setCurrentPage(1);
    setHistoryPage(1);
    setSearchKey("");
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue == 0 && studentStore.visitHistoryList.totalCount == 0) {
      // refresh visit list if history is never load
      studentStore.getVisitListByStudentId(+studentId, true, PAGE_SIZE, (historyPage - 1) * PAGE_SIZE);
    } else if (newValue == 1 && studentStore.visitCurrentList.totalCount == 0) {
      studentStore.getVisitListByStudentId(+studentId, false, PAGE_SIZE, (currentPage - 1) * PAGE_SIZE);
    }
  };

  const handleChangeModalTab = (event: React.SyntheticEvent, newValue: number) => {
    setModalTabValue(newValue);
  }

  const handleShowModal = (id: number, isHistory: boolean) => {
    let target: any;
    // if (isHistory)
    //   target = studentStore.visitHistoryList.data.find(v => v.id == id);
    // else
    //   target = studentStore.visitCurrentList.data.find(v => v.id == id);
    target = studentStore.visitListByTraineeship.find(v => v.id == id);
    console.log(toJS(target));

    setCurrentVisit(target);
    setShowModal(true);
  }

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      var delayInMilliseconds = 500; //1 second
      userStore.setVisitTableLoading(true);
      setTimeout(function () {
        userStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      userStore.getChangeRequestListByVisitId(id);
      setFold(id)
      // onToggleChangeVisitList(id)
    }
  }

  const onToggleTraineeshipFold = (id: number) => {
    if (traineeshipFold === id) {
      setTraineeshipFold(0);
    } else {
      setTraineeshipFold(id);
    }
  };

  return (
    <ContentLayout
      pageName={t('STUDENT_PROFILE')}
      pageHeading=""
      breadCrumb={[
        { label: t('STUDENT_PROFILE') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/student/overview" },
        { label: t('STUDENT_PROFILE'), to: "/student/student-profile/" }
      ]}
      showHeadingButton={false}
    >
      <div className="mb-6" style={{ marginTop: -10 }}>
        {studentStore.studentProfileList.data.length > 0 &&
          <FormControl fullWidth sx={{ with: 300 }}>
            <InputLabel>{t('SELECT_STUDENT')}</InputLabel>
            <Select
              label={t('SELECT_STUDENT')}
              sx={{ width: 300, background: 'white' }}
              value={studentId}
              onChange={onChangeStudent}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {studentStore.studentProfileList.data.map((stu: any, index: number) => (
                <MenuItem key={`stu_select_${index}`} value={stu.id}>
                  {stu.firstName + " " + stu.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
      </div>
      {studentStore.loading ?
        <>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
        </>
        : studentId === "0" || ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) == 0 || !currentStudent
          ? <div className="text-center"><Typography variant="h6">{t('NO_STUDENT_SELECTED')}</Typography></div>
          : <>
            <CollapsedSection sectTitle={t('STUDENT_PROFILE')}>
              <div className="grid grid-cols-4 gap-4">
                <TextField
                  value={currentStudent.title}
                  label={t('TITLE')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentStudent.username}
                  label={t('USERNAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentStudent.firstName}
                  label={t('FIRST_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentStudent.lastName}
                  label={t('LAST_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentStudent.middleName}
                  label={t('MIDDLE_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentStudent.birthDate.split('T')[0]}
                  label={t('BOD')}
                  type="date"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  value={currentStudent.emailAddress}
                  label={t('EMAIL')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  className="col-span-2"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </CollapsedSection>
          </>}

      {currentStudent && <>
        <Typography variant="h5" fontWeight={'bold'} marginBottom={2} marginTop={4}
          sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
          {t('Traineeship List')}
        </Typography>
        <VmTable
          loading={studentStore.loading}
          page={0}
          thead={["ID", t('DOMAIN') + " Code", t('DOMAIN') + ' Title', t('DESCRIPTION_B'), "Employer", t('ACTION')]}
          tbody={currentStudent.traineeships.length > 0 ?
            currentStudent.traineeships.map((ts, index) => (
              <>
                <Box marginY={1} />
                <tr key={`ts_${index}`} style={{cursor: "pointer"}}>
                  <td className={TD_FIRST} onClick={() => onToggleTraineeshipFold(ts.id)}>{index + 1}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(ts.id)}>{ts.qualification.code}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(ts.id)}>{ts.qualification.name}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(ts.id)}>{ts.qualification.description}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(ts.id)}>{ts.organisation.name}</td>
                  <td className={TD_LAST}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setTraineeshipId(ts.id);
                        setSearchKey("");
                        setCurrentTraineeship(ts);
                        studentStore.getVisitListByTraineeshipId(ts.id);
                      }}
                    >
                      {t('VIEW_VISITS')}
                    </Button>
                  </td>
                </tr>
                <TraineeshipCard
                      traineeship={ts}
                      fold={traineeshipFold}
                      colSpan={6}
                    />
                <Box marginY={1} />
              </>
            ))
            : <tr><td className="text-center py-4" colSpan={6}>No Traineeship Data Found</td></tr>}
        />

        {
          traineeshipId > 0 &&
          <>
            <Heading>
              {t('VISIT_LIST')}: {currentStudent.traineeships.find(s => s.id == traineeshipId).qualification.code}
            </Heading>
            <TextField
              value={searchKey}
              label={t('Seach Visit by Date')}
              variant="outlined"
              sx={{ width: 200, marginY: 2, background: 'white' }}
              onChange={(event) => setSearchKey(event.target.value)}
              placeholder="eg. 2022-01-01"
              size="small"
            />
            <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
              <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
              <Typography>Trainer & Assesor Not Check In Yet</Typography>
              <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
              <Typography> Check In By Trainer & Assesor</Typography>
              <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
              <Typography> Check Out By Trainer & Assesor</Typography>
            </Box>
            <VmTable
              loading={studentStore.loadingVisit}
              page={0}
              thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "Check in " + t('STATUS')]}
              tbody={studentStore.visitListByTraineeship
                .filter(v => searchKey === "" ? v.startTime !== ""
                  : (v.startTime.includes(searchKey) || v.endTime.includes(searchKey))).map((visit, index) => {
                    return (
                      <>
                        <Box sx={{ marginY: 1 }} />
                        <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                          <td className={TD_FIRST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "-"}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "-"}</td>
                          <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>
                            <span className={`${visit.checkOutTime ? "bg-green-400" : visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                              <Typography variant="caption">{visit.checkOutTime ? "Check Out by Teacher" : visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                            </span>
                          </td>
                          {/* <td className={TD_LAST}>
                            <Button
                              variant="outlined"
                              onClick={() => handleShowModal(visit.id, true)}
                            >
                              {t('VIEW')}
                            </Button>
                          </td> */}
                        </tr>
                        {/* folder */}
                        <VmVisitSlideDownCard
                          visit={visit}
                          visitChangeList={userStore.visitChangeList.data}
                          loadingVisitTable={userStore.loadingVisit}
                          fold={fold}
                          currentTraineeship={currentTraineeship}
                          colspan={10}
                        />
                        <Box sx={{ marginY: 1 }} />
                      </>
                    );
                  })}
            />
          </>}

        {/* <div className="flex justify-between my-4">
          <Typography variant="h5" fontWeight={'bold'} marginTop={1}
            sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
            {t('VISIT_LIST')}
          </Typography>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label={t('HISTORY_VISIT')} {...TabProps(0)} />
            <Tab label={t('ACTIVE_VISIT')} {...TabProps(1)} />
          </Tabs>
        </div> */}

        {/* History List */}
        {/* <TabPanel value={tabValue} index={0}>
          <VmTable
            loading={studentStore.loadingVisit}
            page={historyPage}
            paginationCount={studentStore.visitHistoryList.totalCount == 0 ? 1 : studentStore.visitHistoryList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setCurrentPage(pageNumber);
              studentStore.getVisitListByStudentId(+studentId, true, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('STATUS'), t('ACTION')]}
            tbody={studentStore.visitHistoryList.data.length > 0 ?
              studentStore.visitHistoryList.data.map((visit, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`visit_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{visit.name}</td>
                    <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>
                      <span className="bg-red-400 rounded-2xl text-white p-2 pt-1">
                        <Typography variant="caption">{t('CHECKED_OUT_BY_TEACHER')}</Typography>
                      </span>
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        onClick={() => handleShowModal(visit.id, true)}
                      >
                        {t('VIEW')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))
              : <tr><td className="text-center py-4" colSpan={6}>{t('NO_RESULT_FOUND')}</td></tr>}
          />
        </TabPanel> */}

        {/* Current List */}
        {/* <TabPanel value={tabValue} index={1}>
          <VmTable
            loading={studentStore.loadingVisit}
            page={currentPage}
            paginationCount={studentStore.visitCurrentList.totalCount == 0 ? 1 : studentStore.visitCurrentList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setCurrentPage(pageNumber);
              studentStore.getVisitListByStudentId(+studentId, false, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('STATUS'), t('ACTION')]}
            tbody={studentStore.visitCurrentList.data.length > 0 ?
              studentStore.visitCurrentList.data.map((visit, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`visit_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{visit.name}</td>
                    <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>
                      <span className={`${visit.checkInTime ? "bg-green-400" : "bg-orange-400"} rounded-2xl text-white p-2 pt-1`}>
                        <Typography variant="caption">{visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                      </span>
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        onClick={() => handleShowModal(visit.id, false)}
                      >
                        {t('VIEW')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))
              : <tr><td className="text-center py-4" colSpan={6}>{t('NO_RESULT_FOUND')}</td></tr>}
          />
        </TabPanel> */}
      </>}


      <VmModal
        openModal={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentVisit(null);
          setModalTabValue(0);
        }}
        width={500}
        title={t('VISIT_INFO')}
        showButton={false}
      >
        {currentVisit &&
          <>
            <Box display="flex" justifyContent="center" width="100%" marginBottom={2}>
              <Tabs
                value={modalTabValue}
                onChange={handleChangeModalTab}
                sx={{ marginTop: -4, textAlign: 'center', justifyContent: 'center' }}
              // TabIndicatorProps={{ style: { background: THEME_COLOR } }}
              >
                <Tab label={t('VISIT_INFO')} {...TabProps(0)} />
                <Tab label={t('UPLOADED_FILES')} {...TabProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={modalTabValue} index={0} paddingValue={0}>
              <table style={{ width: '100%', marginTop: -10 }}>
                <thead><th /><th /></thead>
                <tbody>
                  <tr>
                    <td className="font-bold">{t('Location')}: </td>
                    <td className="text-right">{currentVisit.finalLocation.name}</td>
                  </tr>
                  {/* <tr>
                    <td className="font-bold">{t('STUDENT_SINGLE')}: </td>
                    <td className="text-right">{currentVisit.studentName}</td>
                  </tr> */}
                  <tr>
                    <td className="font-bold">{t('CREATE_TIME')}: </td>
                    <td className="text-right">{currentVisit.createTime.split('T')[0] + " " + currentVisit.createTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('UPDATED_TIME')}: </td>
                    <td className="text-right">{currentVisit.updateTime.split('T')[0] + " " + currentVisit.updateTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('START_TIME')}: </td>
                    <td className="text-right">{currentVisit.finalStartTime.split('T')[0] + " " + currentVisit.finalStartTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('END_TIME')}: </td>
                    <td className="text-right">{currentVisit.finalEndTime.split('T')[0] + " " + currentVisit.finalEndTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Responsible Trainer Name: </td>
                    <td className="text-right">{currentVisit.responsibleTrainerName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Conduct Trainer Name: </td>
                    <td className="text-right">{currentVisit.conductTrainerName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('DESCRIPTION')}: </td>
                  </tr>
                  <tr><td colSpan={2}>
                    <div className="my-2 mt-1">
                      <TextField
                        value={currentVisit.description}
                        rows={2}
                        sx={{ width: '100%' }}
                        disabled
                        multiline
                        size="small"
                      />
                    </div>
                  </td></tr>
                </tbody>
              </table>
              <div className="my-2 mt-1">
                <TextField
                  value={currentVisit.remark ? currentVisit.remark : t('NO_FEEDBACK_YET')}
                  rows={2}
                  sx={{ width: '100%' }}
                  disabled
                  multiline
                  size="small"
                />
              </div>
            </TabPanel>
            <TabPanel value={modalTabValue} index={1} paddingValue={0}>
              {currentVisit.length > 0 &&
                currentVisit.organisationVisitDocuments.length > 0 ?
                <div className="grid grid-cols-5 gap-4">
                  {currentVisit.organisationVisitDocuments.map((doc: any) => (
                    <button className="border hover:border-theme hover-border-2"
                      onClick={() => {
                        if (doc.physicalName.split('.')[1] !== "pdf") {
                          window.open(constructImgUrl(DocumentType.STUDENT_TRAINEESHIP_FILE, FileCategory.FORM_PERSONAL_ID, currentVisit.id, doc.physicalName));
                        } else {
                          fileStore.getFile(DocumentType.STUDENT_TRAINEESHIP_FILE, FileCategory.FORM_PERSONAL_ID, currentVisit.id, doc.physicalName)
                            .then((res) => {
                              const file = new Blob([res], { type: "application/pdf" });
                              const fileURL = URL.createObjectURL(file);
                              window.open(fileURL);
                            })
                        }
                      }} disabled={groupStore.loadingFile}>
                      {doc.documentName.split('.')[1] !== "pdf" ?
                        <img src={constructImgUrl(DocumentType.STUDENT_TRAINEESHIP_FILE, FileCategory.FORM_PERSONAL_ID, currentVisit.id, doc.physicalName)} />
                        : groupStore.loadingFile ? <CircularProgress className="text-theme" />
                          : <>
                            <FilePresentIcon fontSize={"large"} />
                            <Typography variant="caption">PDF</Typography>
                          </>}
                    </button>
                  ))}
                </div>
                : <Typography textAlign="center">{t('NO_DOCUMENT_UPLOADED_IN_THIS_VISIT')}</Typography>}
            </TabPanel>
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default StudentProfilePage;
